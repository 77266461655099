import React, { useState, useContext } from "react";
import UserContext from "../../helpers/user/UserContext";
import SearchLocationInput from "../common/google-places";
import {
  Input,
  Container,
  Row,
  Form,
  Label,
  Col,
  Button,
  Alert,
} from "reactstrap";
import axios from "axios";

const Register = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [formError, setFormError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [registerFormData, setRegisterFormData] = useState({
    fname: "",
    lname: "",
    email: "",
    password: "",
    verifyPassword: "",
    mobile: "",
    address: "",
  });

  const inputHandler = (e) => {
    setRegisterFormData({
      ...registerFormData,
      [e.target.name]: e.target.value,
    });
  };

  const setAddress = (address) => {
    setRegisterFormData((prevFormData) => ({
      ...prevFormData,
      address: address,
    }));
  };

  const submithandler = (e) => {
    e.preventDefault();

    const formData = new FormData();
    Object.keys(registerFormData).forEach((key) => {
      formData.append(key, registerFormData[key]);
    });

    axios
      .post(`${baseUrl}/seller/register/`, formData)
      .then((response) => {
        if (response.data.bool === false) {
          setFormError(true);
          setErrorMsg(response.data.msg);
          setSuccessMsg("");
        } else {
          setFormError(false);
          setErrorMsg("");
          setSuccessMsg("Compte créé avec succès !");
        }
      })
      .catch((error) => {
        setFormError(true);
        setErrorMsg("Une erreur s'est produite lors de la création du compte.");
        setSuccessMsg("");
      });
  };

  return (
    <section className="register-page section-b-space">
      <Container>
        <Row>
          <Col lg="12">
            <h3>créer un compte</h3>
            <div className="theme-card">
              <Form className="theme-form" onSubmit={submithandler}>
                {formError && <Alert color="danger">{errorMsg}</Alert>}
                {successMsg && <Alert color="success">{successMsg}</Alert>}

                <Row>
                  <Col md="6">
                    <Label for="fname" className="form-label">
                      Prénom
                    </Label>
                    <Input
                      type="text"
                      name="fname"
                      id="fname"
                      required
                      value={registerFormData.fname}
                      onChange={inputHandler}
                    />
                  </Col>
                  <Col md="6">
                    <Label for="lname" className="form-label">
                      Nom
                    </Label>
                    <Input
                      type="text"
                      name="lname"
                      id="lname"
                      required
                      value={registerFormData.lname}
                      onChange={inputHandler}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <Label for="email" className="form-label">
                      E-mail
                    </Label>
                    <Input
                      type="email"
                      name="email"
                      id="email"
                      required
                      value={registerFormData.email}
                      onChange={inputHandler}
                    />
                  </Col>
                  <Col md="6">
                    <Label for="mobile" className="form-label">
                      Numéro de télémobile
                    </Label>
                    <Input
                      type="tel"
                      name="mobile"
                      id="mobile"
                      required
                      value={registerFormData.mobile}
                      onChange={inputHandler}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <Label for="password" className="form-label">
                      Mot de passe
                    </Label>
                    <Input
                      type="password"
                      name="password"
                      id="password"
                      required
                      value={registerFormData.password}
                      onChange={inputHandler}
                    />
                  </Col>
                  <Col md="6">
                    <Label for="verifyPassword" className="form-label">
                      Vérifier le mot de passe
                    </Label>
                    <Input
                      type="password"
                      name="verifyPassword"
                      id="verifyPassword"
                      required
                      value={registerFormData.verifyPassword}
                      onChange={inputHandler}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <Label for="address" className="form-label">
                      Adresse
                    </Label>
                    <SearchLocationInput setSelectedLocation={setAddress} />
                  </Col>
                  <Col md="12" style={{ marginTop: "10px" }}>
                    <Button type="submit" className="btn btn-solid w-auto">
                      Créer un compte
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Register;
