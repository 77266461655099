import axios from 'axios';

const baseUrl = process.env.REACT_APP_BASE_URL;

export const refreshToken = async () => {
	const refreshToken = localStorage.getItem('refresh_token');
	if (!refreshToken) {
		console.error('No refresh token found');
		return null;
	}
	try {
		const response = await axios.post(`${baseUrl}/token/refresh/`, {
			refresh: refreshToken,
		});
		const newToken = response.data.access;
		localStorage.setItem('token', newToken);
		return newToken;
	} catch (error) {
		console.error('Error refreshing token:', error);
		return null;
	}
};

export const getValidToken = async () => {
	let token = localStorage.getItem('token');

	if (!token) {
		console.log("Aucun token d'accès trouvé dans localStorage");
		token = await refreshToken();
		if (!token) {
			console.log('Échec du rafraîchissement du token');
			return null;
		}
	} else {
		try {
			const decodedToken = JSON.parse(atob(token.split('.')[1]));
			const currentTime = Date.now() / 1000;

			if (decodedToken.exp < currentTime) {
				console.log('Token expiré, tentative de rafraîchissement');
				token = await refreshToken();
				if (!token) {
					console.log('Échec du rafraîchissement du token');
					return null;
				}
			}
		} catch (error) {
			console.error('Erreur lors du décodage du token:', error);
			token = await refreshToken();
			if (!token) {
				console.log('Échec du rafraîchissement du token après erreur de décodage');
				return null;
			}
		}
	}

	console.log('Token valide retourné');
	return token;
};
