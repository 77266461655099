import React, { useState } from 'react';
import axios from 'axios';
import { Container, Row, Form, Label, Input, Col, Alert, Button } from 'reactstrap';
import { useNavigate } from 'react-router-dom';

const SellerLogin = () => {
	const [formError, setFormError] = useState(false);
	const [errorMsg, setErrorMsg] = useState('');
	const baseUrl = process.env.REACT_APP_BASE_URL;

	const [loginFormData, setLoginFormData] = useState({
		email: '',
		password: '',
	});
	const navigate = useNavigate();

	const inputHandler = (e) => {
		setLoginFormData({
			...loginFormData,
			[e.target.name]: e.target.value,
		});
	};

	const submithandler = (e) => {
		e.preventDefault();

		const formData = new FormData();
		formData.append('email', loginFormData.email);
		formData.append('password', loginFormData.password);

		axios
			.post(`${baseUrl}/seller/login/`, formData)
			.then((response) => {
				console.log('Réponse du serveur:', response.data);
				if (response.data.bool === false) {
					setFormError(true);
					setErrorMsg(response.data.msg);
				} else {
					setFormError(false);
					setErrorMsg('');
					localStorage.setItem('seller_id', response.data.seller_id);
					localStorage.setItem('token', response.data.token);
					localStorage.setItem('isLogged', 'true');
					localStorage.setItem('refresh_token', response.data.refresh_token);
					console.log('Token stocké:', localStorage.getItem('token'));
					navigate('/dashboard');
				}
			})
			.catch((error) => {
				console.error('Erreur de connexion:', error);
				setFormError(true);
				setErrorMsg('Erreur de connexion. Veuillez réessayer.');
			});
	};

	return (
		<Container>
			<Row>
				<Col md='6'>
					<Form className='theme-form'>
						<div className='form-group'>
							<Label for='email'>Email</Label>
							<Input type='email' className='form-control' id='email' name='email' value={loginFormData.email} placeholder='Email' onChange={inputHandler} required />
						</div>
						<div className='form-group'>
							<Label for='password'>Mot de passe</Label>
							<Input type='password' className='form-control' id='password' name='password' value={loginFormData.password} placeholder='Entrez votre mot de passe' onChange={inputHandler} required />
						</div>
						<Button onClick={submithandler} className='btn btn-solid' disabled={!(loginFormData.email && loginFormData.password)}>
							Connexion
						</Button>
						{formError && (
							<Alert color='warning' style={{ marginTop: '20px' }}>
								{errorMsg}
							</Alert>
						)}
					</Form>
				</Col>
			</Row>
		</Container>
	);
};

export default SellerLogin;
