import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { Container, Row, Col, Card, CardBody, Button } from 'reactstrap';

const StripeReturn = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const [status, setStatus] = useState('loading');
	const [message, setMessage] = useState('');

	useEffect(() => {
		const searchParams = new URLSearchParams(location.search);
		const status = searchParams.get('status');
		const message = searchParams.get('message');

		setStatus(status || 'error');
		setMessage(message || 'An error occurred during Stripe onboarding');
	}, [location]);

	const handleContinue = () => {
		navigate('/settings/profile', { state: { stripeStatus: status, message: message } });
	};

	return (
		<Container className='d-flex align-items-center' style={{ minHeight: '100vh' }}>
			<Row className='justify-content-center w-100' style={{ marginTop: '-16.67vh' }}>
				<Col md={6}>
					<Card>
						<CardBody className='text-center'>
							<h2>{status === 'success' ? 'Bravo !' : 'Onboarding'}</h2>
							<p>Intégration terminée avec succès</p>
							<Button color='primary' onClick={handleContinue}>
								Continuer vers le profile
							</Button>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

export default StripeReturn;
