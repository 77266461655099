import React, { Fragment, useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import Breadcrumb from '../common/breadcrumb';
import { Card, CardBody, Col, Container, Media, Row, Button, Alert, Spinner } from 'reactstrap';
import TabsetProfile from './tabset-profile';
import designer from '../../assets/images/dashboard/designer.jpg';
import { getValidToken } from '../../helpers/auth';
import { useLocation } from 'react-router-dom';

const Profile = () => {
	const [seller, setSeller] = useState(null);
	const [stripeAccountId, setStripeAccountId] = useState(null);
	const [isStripeVerified, setIsStripeVerified] = useState(null);
	const [stripeMessage, setStripeMessage] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const location = useLocation();
	const baseUrl = process.env.REACT_APP_BASE_URL;
	const fetchedRef = useRef(false);

	const [seller_id] = useState(() => localStorage.getItem('seller_id'));

	const fetchSellerData = useCallback(async () => {
		if (seller_id && !fetchedRef.current) {
			try {
				const response = await axios.get(`${baseUrl}/seller/${seller_id}/`);
				console.log('API response:', response.data);
				setSeller(response.data);
				setStripeAccountId(response.data.stripe_account_id);
				setIsStripeVerified(response.data.is_stripe_verified);
				fetchedRef.current = true;
			} catch (error) {
				console.error('Erreur lors de la récupération des données du vendeur:', error);
			} finally {
				setIsLoading(false);
			}
		} else if (!seller_id) {
			console.error('seller_id non trouvé dans le localStorage');
			setIsLoading(false);
		}
	}, [baseUrl, seller_id]);

	useEffect(() => {
		fetchSellerData();
	}, [fetchSellerData]);

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const stripeStatus = urlParams.get('stripe_status');
		console.log('Stripe status from URL:', stripeStatus);

		if (stripeStatus === 'success') {
			setIsStripeVerified(true);
			setStripeMessage({ type: 'success', text: 'Votre compte Stripe a été vérifié avec succès.' });
		} else if (stripeStatus === 'incomplete') {
			setStripeMessage({ type: 'warning', text: 'La vérification de votre compte Stripe est incomplète. Veuillez terminer le processus.' });
		}
	}, [location]);

	useEffect(() => {
		console.log('Profile component mounted/updated');
		console.log('Current seller state:', seller);
		console.log('Is Stripe verified:', isStripeVerified);
	}, [seller, isStripeVerified]);

	const handleStripeConnect = async () => {
		setIsLoading(true);
		try {
			const token = await getValidToken();
			if (!token) {
				console.error('Token non valide');
				window.location.href = '/login';
				return;
			}

			const headers = {
				Authorization: `Bearer ${token}`,
				'Content-Type': 'application/json',
			};

			let accountId = stripeAccountId;
			if (!accountId) {
				const createResponse = await axios.post(`${baseUrl}/stripe/create-account/`, {}, { headers });
				accountId = createResponse.data.account_id;
				setStripeAccountId(accountId);
			}

			const linkResponse = await axios.post(`${baseUrl}/stripe/create-account-link/`, { account_id: accountId }, { headers });

			if (linkResponse.data && linkResponse.data.url) {
				window.location.href = linkResponse.data.url;
			} else {
				console.error('URL Stripe invalide reçue:', linkResponse.data);
				setStripeMessage({ type: 'danger', text: 'Erreur lors de la connexion à Stripe. Veuillez réessayer.' });
			}
		} catch (error) {
			console.error('Erreur lors de la connexion à Stripe:', error);
			setStripeMessage({ type: 'danger', text: 'Erreur lors de la connexion à Stripe. Veuillez réessayer.' });
		} finally {
			setIsLoading(false);
		}
	};

	if (isLoading) {
		return <Spinner color='primary' />;
	}

	return (
		<Fragment>
			<Breadcrumb title='Profile' parent='Settings' />
			{stripeMessage && (
				<Alert color={stripeMessage.type} style={{ margin: '20px' }}>
					{stripeMessage.text}
				</Alert>
			)}
			{isStripeVerified ? (
				<Button color='secondary' onClick={() => window.open('https://dashboard.stripe.com', '_blank')}>
					Accéder au tableau de bord Stripe
				</Button>
			) : (
				<Button color='primary' onClick={handleStripeConnect} disabled={isLoading}>
					{isLoading ? (
						<>
							<Spinner size='sm' color='light' /> Chargement...
						</>
					) : (
						'Se connecter à Stripe'
					)}
				</Button>
			)}
			<Container fluid={true}>
				<Row>
					<Col xl='4'>
						<Card>
							<CardBody>
								<div className='profile-details text-center'>
									<img src={designer} alt='' className='img-fluid img-90 rounded-circle blur-up lazyloaded' />
									<h5 className='f-w-600 f-16 mb-0'>John deo</h5>
									<span>johndeo@gmail.com</span>
									<div className='social'>
										<div className='form-group btn-showcase'>
											<Button color='btn social-btn btn-fb d-inline-block'>
												{' '}
												<i className='fa fa-facebook'></i>
											</Button>
											<Button color='btn social-btn btn-twitter d-inline-block'>
												<i className='fa fa-google'></i>
											</Button>
											<Button color='btn social-btn btn-google d-inline-block me-0'>
												<i className='fa fa-twitter'></i>
											</Button>
										</div>
									</div>
								</div>
								<hr />
								<div className='project-status'>
									<h5 className='f-w-600 f-16'>Statut de l'employé</h5>
									<Media>
										<Media body>
											<h6>
												Performance <span className='pull-right'>80%</span>
											</h6>
											<div className='progress sm-progress-bar'>
												<div className='progress-bar bg-primary' role='progressbar' style={{ width: '90%' }} aria-valuenow='25' aria-valuemin='0' aria-valuemax='100'></div>
											</div>
										</Media>
									</Media>
									<Media>
										<Media body>
											<h6>
												Heures supplémentaires <span className='pull-right'>60%</span>
											</h6>
											<div className='progress sm-progress-bar'>
												<div className='progress-bar bg-secondary' role='progressbar' style={{ width: '60%' }} aria-valuenow='25' aria-valuemin='0' aria-valuemax='100'></div>
											</div>
										</Media>
									</Media>
									<Media>
										<Media body>
											<h6>
												Congés pris <span className='pull-right'>50%</span>
											</h6>
											<div className='progress sm-progress-bar'>
												<div className='progress-bar bg-danger' role='progressbar' style={{ width: '50%' }} aria-valuenow='25' aria-valuemin='0' aria-valuemax='100'></div>
											</div>
										</Media>
									</Media>
								</div>
							</CardBody>
						</Card>
					</Col>
					<Col xl='8'>
						<Card className='profile-card'>
							<CardBody>
								<TabsetProfile />
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};

export default Profile;
