import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

const StripeRefresh = () => {
	const { accountId } = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		const refreshOnboarding = async () => {
			try {
				const response = await axios.post('/stripe/create-account-link/', {
					account_id: accountId,
				});
				window.location.href = response.data.url;
			} catch (error) {
				console.error('Error refreshing Stripe onboarding:', error);
				navigate('/settings/profile');
			}
		};
		refreshOnboarding();
	}, [accountId, navigate]);

	return <div>Refreshing Stripe onboarding...</div>;
};

export default StripeRefresh;
