import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../common/breadcrumb";
import Datatable from "../common/datatable";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import axios from "axios";

const Orders = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [orderItems, setOrderItems] = useState([]);
  const sellerId = localStorage.getItem("seller_id");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const { data } = await axios(
        `${baseUrl}/seller/${sellerId}/order-items/`
      );
      setOrderItems(data.data || []);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };
  console.log("orderItems:", orderItems);

  return (
    <Fragment>
      <Breadcrumb title="Orders" parent="Sales" />

      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>Manage Order</h5>
              </CardHeader>
              <CardBody className="order-datatable">
                <Datatable
                  myData={orderItems}
                  myClass="-striped -highlight"
                  pagination={true}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Orders;
