import React, { Fragment, useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
} from "reactstrap";
import Breadcrumb from "../../common/breadcrumb";
import ProductListview from "./product-listview";
import ProductListcard from "./product-listcard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { faThList, faThLarge } from "@fortawesome/free-solid-svg-icons";
import ConfirmModal from "../../common/confirmModal";

const ProductList = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const seller_id = localStorage.getItem("seller_id");

  const navigate = useNavigate();
  const [isListView, setIsListView] = useState(true);
  const [products, setProducts] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentProductId, setCurrentProductId] = useState(null);

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const { data } = await axios(
        `${baseUrl}/products/?seller_id=${seller_id}`
      );
      setProducts(data.data || []);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const toggleModal = () => setModalOpen(!modalOpen);

  const promptDeleteProduct = (productId) => {
    setCurrentProductId(productId);
    toggleModal();
  };

  const handleDeleteConfirm = () => {
    if (currentProductId) {
      axios
        .delete(`${baseUrl}/product/delete/${currentProductId}/`)
        .then(() => {
          refreshProductsList();
          toggleModal();
        })
        .catch((error) => {
          console.error("Error during product deletion:", error);
          toggleModal();
        });
    }
  };

  const refreshProductsList = () => {
    fetchProducts();
  };

  const handleEditClick = (productId) => {
    navigate(`/products/physical/update-product/${productId}`);
  };

  return (
    <Fragment>
      <Breadcrumb title="Product List" parent="Physical" />
      <Container fluid={true}>
        <div className="d-flex justify-content-end mb-2">
          <Button color="primary" onClick={() => setIsListView(!isListView)}>
            <FontAwesomeIcon icon={isListView ? faThLarge : faThList} />
            {isListView ? " Vue en carte" : " Vue en liste"}
          </Button>
        </div>
        {isListView ? (
          <ProductListview
            products={products}
            handleEditClick={handleEditClick}
            handleDeleteClick={promptDeleteProduct}
          />
        ) : (
          <ProductListcard
            products={products}
            handleEditClick={handleEditClick}
            handleDeleteClick={promptDeleteProduct}
          />
        )}
        {/* Modal de confirmation */}
        <ConfirmModal
          modalOpen={modalOpen}
          toggleModal={toggleModal}
          handleDeleteConfirm={handleDeleteConfirm}
        />
      </Container>
    </Fragment>
  );
};

export default ProductList;
