import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Button,
  CustomInput,
  TextArea,
  Alert,
} from "reactstrap";
import MDEditor from "@uiw/react-md-editor";
import Breadcrumb from "../../common/breadcrumb";
import axios from "axios";

const AddProduct = () => {
  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  console.log("Base URL:", baseUrl);

  const [brands, setBrands] = useState([]);
  const brandOptions = brands.map((brand) => ({
    value: brand.id, // Supposons que chaque marque a un 'id' et un 'name'
    label: brand.title,
  }));
  const [colors, setColors] = useState([]);
  const colorOptions = colors.map((color) => ({
    value: color.id,
    label: color.title,
  }));
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
  const seller_id = localStorage.getItem("seller_id");
  const [errorMessages, setErrorMessages] = useState();
  const [successMessage, setSuccessMessage] = useState("");
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [isFormValid, setIsFormValid] = useState(false);

  // State for posting product
  const [title, setTitle] = useState("");
  const [detail, setDetail] = useState("");
  const [price, setPrice] = useState("");
  const [stock, setStock] = useState(1);
  const [status, setStatus] = useState("Verification");
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState([]);
  const [seller, setSeller] = useState("");
  const [color, setColor] = useState("");
  const [sizeLetter, setSizeLetter] = useState("");
  const [sizeNumber, setSizeNumber] = useState("");
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  const [brand, setBrand] = useState("");
  const [norefBrand, setNorefBrand] = useState("");
  const [images, setImages] = useState([
    { img: null },
    { img: null },
    { img: null },
  ]);

  useEffect(() => {
    checkFormValidity();
  }, [
    title,
    price,
    stock,
    category,
    images,
    color,
    brand,
    norefBrand,
    selectedColor,
    selectedBrand,
    subCategory,
  ]);

  const checkFormValidity = () => {
    const imagesValid =
      images.filter((image) => image.img != null).length === 3;
    const requiredFieldsValid =
      title.trim() !== "" && price > 0 && stock > 0 && category;
    const additionalChecks = selectedBrand
      ? selectedBrand.label === "Autre"
        ? norefBrand.trim() !== ""
        : true
      : true;

    setIsFormValid(imagesValid && requiredFieldsValid && additionalChecks);
  };

  const handleImageChange = (event, index) => {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        const updatedImages = [...images];
        updatedImages[index].img = e.target.result;
        setImages(updatedImages);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const handleBrandChange = (selectedOption) => {
    setSelectedBrand(selectedOption);
    setBrand(selectedOption.value);
  };

  const handleColorChange = (selectedOption) => {
    setSelectedColor(selectedOption);
    setColor(selectedOption.value);
  };

  const handleCategoryChange = (e) => {
    const selectedCategoryId = e.target.value;
    setCategory(selectedCategoryId);

    const selectedCategory = categories.find(
      (category) => category.id.toString() === selectedCategoryId
    );

    if (selectedCategory && selectedCategory.subcategories) {
      setSubCategories(selectedCategory.subcategories);
    } else {
      setSubCategories([]);
    }
  };

  const handleSubCategoryChange = (subCatId) => {
    setSubCategory((prevSubCategory) => {
      if (prevSubCategory.includes(subCatId)) {
        return prevSubCategory.filter((id) => id !== subCatId);
      } else {
        return [...prevSubCategory, subCatId];
      }
    });
  };
  // Fetch categories from the API
  useEffect(() => {
    fetch(`${baseUrl}/categories/`)
      .then((response) => response.json())
      .then((data) => setCategories(data.data));
  }, []);

  useEffect(() => {
    fetch(`${baseUrl}/brands/?no_pagination=true`)
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data)) {
          setBrands(data);
        }
      })
      .catch((error) => {
        setBrands([]);
        console.error("Error fetching brands:", error);
      });
  }, []);

  function base64ToFile(dataURI, filename) {
    let byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0) {
      byteString = atob(dataURI.split(",")[1]);
    } else {
      byteString = unescape(dataURI.split(",")[1]);
    }
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new File([ia], filename, { type: mimeString });
  }

  useEffect(() => {
    fetch(`${baseUrl}/colors/?no_pagination=true`)
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data)) {
          setColors(data);
        }
      })
      .catch((error) => {
        setColors([]);
        console.error("Error fetching colors:", error);
      });
  }, []);

  const submitHandler = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("seller_id", seller_id);
    formData.append("title", title);
    formData.append("detail", detail);
    formData.append("price", parseInt(price));
    formData.append("stock", stock);
    formData.append("status", status);
    formData.append("category", category);
    subCategory.forEach((subCatId) => {
      formData.append("subcategories", subCatId);
    });
    formData.append("seller", seller);
    formData.append("color_id", color);
    formData.append("size_letter", sizeLetter);
    formData.append("size_number", sizeNumber);
    formData.append("width", width);
    formData.append("height", height);
    formData.append("brand_id", brand);
    formData.append("noref_brand", norefBrand);
    images.forEach((image, index) => {
      if (image.img) {
        const file = base64ToFile(image.img, `image${index}.png`);
        formData.append(`images`, file);
      }
    });
    for (let [key, value] of formData.entries()) {
      console.log("LOG GENERAL: ", `${key}: ${value}`);
    }
    axios
      .post(`${baseUrl}/product/add/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setSuccessMessage(response?.data?.message);
        navigate("/products/physical/product-list");
      })
      .catch((error) => {
        console.error("Erreur lors de la soumission :", error);
        setErrorMessages(
          error?.response?.data?.errors || ["Une erreur est survenue"]
        );
      });
  };

  return (
    <Container fluid={true}>
      <Breadcrumb title="Ajouter produit" parent="Physical" />
      <Row>
        <Col sm="12">
          <Card>
            <CardHeader>
              <h5>Ajouter un produit</h5>
            </CardHeader>
            <CardBody>
              <Form>
                <FormGroup row>
                  <Label for="title" sm={3}>
                    Titre:
                  </Label>
                  <Col sm={9}>
                    <Input
                      id="title"
                      name="title"
                      placeholder="Nom du produit"
                      type="text"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      required
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="category" sm={3}>
                    Catégorie:
                  </Label>
                  <Col sm={9}>
                    <Input
                      type="select"
                      id="category"
                      name="category"
                      value={category}
                      onChange={handleCategoryChange}
                      required
                    >
                      <option value="">Sélectionner une catégorie</option>
                      {categories.map((category) => (
                        <option key={category.id} value={category.id}>
                          {category.title}
                        </option>
                      ))}
                    </Input>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="subCategory" sm={3}>
                    Sous-catégorie(s) :
                  </Label>
                  <Col sm={9}>
                    {/* Générer des cases à cocher pour les sous-catégories */}
                    {subCategories.map((subCat) => (
                      <CustomInput
                        style={{ margin: "5px" }}
                        type="checkbox"
                        id={`subCategory-${subCat.id}`}
                        label={subCat.title}
                        checked={subCategory.includes(subCat.id)}
                        onChange={() => handleSubCategoryChange(subCat.id)}
                        key={subCat.id}
                      />
                    ))}
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Label for="detail" sm={3}>
                    Detail:
                  </Label>
                  <Col sm={9}>
                    <MDEditor value={detail} onChange={setDetail} />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="price" sm={3}>
                    Price:
                  </Label>
                  <Col sm={9}>
                    <Input
                      id="price"
                      name="price"
                      placeholder="Price"
                      type="number"
                      onWheel={(e) => e.target.blur()} // Ajout de cette ligne
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}
                      required
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="stock" sm={3}>
                    Stock:
                  </Label>
                  <Col sm={9}>
                    <Input
                      id="stock"
                      name="stock"
                      placeholder="Stock"
                      type="number"
                      onWheel={(e) => e.target.blur()} // Ajout de cette ligne
                      value={stock}
                      onChange={(e) => setStock(e.target.value)}
                      required
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="size_letter" sm={3}>
                    Taille (Lettre) :
                  </Label>
                  <Col sm={9}>
                    <Input
                      type="select"
                      id="size_letter"
                      name="size_letter"
                      value={sizeLetter}
                      onChange={(e) => setSizeLetter(e.target.value)}
                      required
                    >
                      <option value="XS">Double Extra Small (XS) </option>
                      <option value="XS">Extra Small (XS) </option>
                      <option value="S">Small (S)</option>
                      <option value="M">Medium (M)</option>
                      <option value="L">Large(L)</option>
                      <option value="XL">Extra Large(XL)</option>
                      <option value="XXL">Double Extra Large(XXL)</option>
                    </Input>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="size_number" sm={3}>
                    Taille (Nombre):
                  </Label>
                  <Col sm={9}>
                    <Input
                      id="size_number"
                      name="size_number"
                      placeholder="Ecrire la taille si c'est un nombre"
                      type="number"
                      onWheel={(e) => e.target.blur()} // Ajout de cette ligne
                      value={sizeNumber}
                      onChange={(e) => setSizeNumber(e.target.value)}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="width" sm={3}>
                    Width:
                  </Label>
                  <Col sm={9}>
                    <Input
                      id="width"
                      name="width"
                      placeholder="Width"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      value={width}
                      onChange={(e) => setWidth(e.target.value)}
                    />
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Label for="height" sm={3}>
                    Height:
                  </Label>
                  <Col sm={9}>
                    <Input
                      id="height"
                      name="height"
                      placeholder="Height"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      value={height}
                      onChange={(e) => setHeight(e.target.value)}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="brand" sm={3}>
                    Brand:
                  </Label>
                  <Col sm={9}>
                    <Select
                      id="brand"
                      name="brand"
                      value={selectedBrand}
                      onChange={handleBrandChange}
                      options={brandOptions}
                      placeholder="Select or search a brand..."
                    />
                  </Col>
                </FormGroup>

                {selectedBrand && selectedBrand.label == "Autre" && (
                  <FormGroup row>
                    <Label for="noref_brand" sm={3}>
                      Spécifier la marque :
                    </Label>
                    <Col sm={9}>
                      <Input
                        id="noref_brand"
                        name="noref_brand"
                        placeholder="Nom de la marque non répertoriée"
                        value={norefBrand}
                        onChange={(e) => setNorefBrand(e.target.value)}
                      />
                    </Col>
                  </FormGroup>
                )}
                <FormGroup row>
                  <Label for="color" sm={3}>
                    Color:
                  </Label>
                  <Col sm={9}>
                    <Select
                      id="color"
                      name="color"
                      value={selectedColor}
                      onChange={handleColorChange}
                      options={colorOptions}
                      placeholder="Select or search a color..."
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="images" sm={3}>
                    Ajouter au minimum 3 images : Face, Latéral et dos
                  </Label>
                  <Col sm={9}>
                    {images.map((image, index) => (
                      <div key={index} style={{ marginTop: "10px" }}>
                        <Label
                          for={`image${index}`}
                          style={{ display: "block" }}
                        >
                          Image {index + 1}:{" "}
                        </Label>
                        <Input
                          type="file"
                          name={`image${index}`}
                          onChange={(e) => handleImageChange(e, index)}
                        />
                        {image.img && (
                          <img
                            src={image.img}
                            alt={`product_image_${index}`}
                            style={{
                              width: 100,
                              height: 100,
                              marginTop: "10px",
                            }}
                          />
                        )}
                      </div>
                    ))}
                  </Col>
                </FormGroup>

                <Button
                  onClick={submitHandler}
                  type="submit"
                  color="primary"
                  disabled={!isFormValid}
                >
                  Ajouter
                </Button>
              </Form>
              {errorMessages && (
                <div>
                  <Alert color="danger">
                    {errorMessages.map((error, index) => (
                      <p key={index}>{error}</p>
                    ))}
                  </Alert>
                </div>
              )}
              {successMessage && (
                <div>
                  <Alert color="success">{successMessage}</Alert>
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default AddProduct;
