// Change image from URL to File
const fetchImageAsFile = async (url, name) => {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Failed to fetch image from ${url}`);
    }
    const blob = await response.blob();
    console.log(`Fetched image from ${url}`);
    return new File([blob], name, { type: blob.type });
  } catch (error) {
    console.error("Error fetching image as file:", error);
    return null;
  }
};

export { fetchImageAsFile };
