import React from "react";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { Edit, Trash2, Search } from "react-feather";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const ProductListcard = ({ products, handleEditClick, handleDeleteClick }) => {
  return (
    <Row className="products-admin ratio_asos">
      {products.map((product, index) => (
        <Col xl="3" sm="6" key={index}>
          <Card>
            <div className="products-admin">
              <CardBody className="product-box">
                <div className="img-wrapper">
                  <div className="front">
                    <a href={`/#/product/${product.id}`} className="bg-size">
                      <img
                        alt=""
                        className="img-fluid blur-up bg-img lazyloaded"
                        src={
                          product.product_images[0]?.image ||
                          "default-image-url"
                        } // Use the first image, if exists
                      />
                    </a>
                    <div className="product-hover">
                      <ul>
                        <li>
                          <Button color="btn" type="button">
                            <Edit
                              onClick={() => handleEditClick(product.id)}
                              className="editBtn"
                            />
                          </Button>
                        </li>
                        <li>
                          <Button color="btn" type="button">
                            <Trash2
                              onClick={() => handleDeleteClick(product.id)}
                              className="deleteBtn"
                            />
                          </Button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="product-detail">
                  <a href={`/#/product/${product.id}`}>
                    <h6>{product.title}</h6>
                  </a>
                  <h4>
                    {product.price} <del>{product.discount_price}</del>
                  </h4>
                  {/* Product ratings */}
                  <div className="rating">
                    {[...Array(5)].map((_, i) => (
                      <i
                        key={i}
                        className={`fa ${
                          i < product.average_rating ? "fa-star" : "fa-star-o"
                        }`}
                      ></i>
                    ))}
                  </div>
                  {/* Color variants can be mapped here if they are part of your product model */}
                </div>
              </CardBody>
            </div>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default ProductListcard;
