import React, { Fragment, useEffect } from 'react';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import Auth from '../components/auth/auth';
import LayoutRoutes from './LayoutRoutes';

const Routers = () => {
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		const isLogged = localStorage.getItem('isLogged');
		if (location.pathname === `${process.env.PUBLIC_URL}/auth/login` && isLogged !== 'true') {
			return;
		} else if (isLogged !== 'true') {
			navigate(`${process.env.PUBLIC_URL}/auth/login`);
		} else if (isLogged === 'true' && (location.pathname === `${process.env.PUBLIC_URL}/auth/login` || location.pathname === '/' || location.pathname === '/login')) {
			navigate(`${process.env.PUBLIC_URL}/dashboard`);
		}
	}, [navigate, location.pathname]);

	return (
		<Fragment>
			<Routes>
				<Route path={`${process.env.PUBLIC_URL}/auth/login`} element={<Auth />} />
				<Route path={`/`} element={<LayoutRoutes />} />
				<Route path={`/*`} element={<LayoutRoutes />} />
			</Routes>
		</Fragment>
	);
};

export default Routers;
