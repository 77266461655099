import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const ConfirmModal = ({ modalOpen, toggleModal, handleDeleteConfirm }) => {
  return (
    <Modal isOpen={modalOpen} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>Confirmer la suppression</ModalHeader>
      <ModalBody>Êtes-vous sûr de vouloir supprimer ce produit ?</ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={handleDeleteConfirm}>
          Supprimer
        </Button>{" "}
        <Button color="secondary" onClick={toggleModal}>
          Annuler
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmModal;
