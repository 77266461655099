// Datatable.js
import React, { Fragment, useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import axios from "axios";

const Datatable = ({ myData, myClass, pagination }) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [currentOrder, setCurrentOrder] = useState(null);
  const [trackingNumber, setTrackingNumber] = useState("");

  useEffect(() => {
    setData(myData);
  }, [myData]);

  const handleDelete = (index) => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer cet élément ?")) {
      const newData = data.filter((_, idx) => idx !== index);
      setData(newData);
      toast.success("Élément supprimé avec succès !");
    }
  };

  const onOpenModal = (order) => {
    setCurrentOrder(order);
    setOpen(true);
  };
  const onCloseModal = () => setOpen(false);

  const handleValidate = (order) => {
    updateOrderStatus(order, "Processing");
  };

  const handleShip = () => {
    if (currentOrder) {
      updateOrderStatus(currentOrder, "Shipped", trackingNumber);
      onCloseModal();
    }
  };

  const updateOrderStatus = async (order, newStatus, trackingNumber = "") => {
    try {
      const response = await axios.patch(
        `${baseUrl}/update-order/${order.id}/`,
        {
          status: newStatus,
          tracking_number: trackingNumber,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const updatedData = data.map((item) =>
        item.id === order.id ? { ...item, order_details: response.data } : item
      );
      setData(updatedData);
      toast.success("Statut mis à jour avec succès !");
    } catch (error) {
      toast.error("Erreur lors de la mise à jour du statut !");
    }
  };

  const getBadgeClass = (status) => {
    switch (status) {
      case "Paid":
        return "badge badge-success";
      case "Awaiting Authentication":
      case "PaymentPending":
        return "badge badge-warning";
      case "Payment Failed":
      case "Cancelled":
        return "badge badge-danger";
      case "Delivery":
      case "Delivered":
        return "badge badge-primary";
      default:
        return "badge badge-secondary";
    }
  };

  const translateStatus = (status) => {
    switch (status) {
      case "PaymentPending":
        return "En attente de paiement";
      case "PaymentDeclined":
        return "Paiement refusé";
      case "Pending":
        return "En attente";
      case "Processing":
        return "En préparation";
      case "Shipped":
        return "Expédié";
      case "Delivered":
        return "Livré";
      case "Cancelled":
        return "Annulé";
      case "Refunded":
        return "Remboursé";
      default:
        return status;
    }
  };

  const columns = [
    {
      name: "Nom du produit",
      selector: (row) => row.product_details?.title || "N/A",
      sortable: true,
      reorder: true,
    },
    {
      name: "Commande",
      selector: (row) => row.order,
      sortable: true,
    },
    {
      name: "Prix",
      selector: (row) => `${row.product_details?.price} €` || "N/A",
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) =>
        new Date(row.order_details?.created_at).toLocaleDateString("fr-FR"),
      sortable: true,
      right: true,
    },
    {
      name: "Couleur",
      selector: (row) => row.product_details?.color?.title || "N/A",
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => (
        <span className={getBadgeClass(row.order_details?.status)}>
          {translateStatus(row.order_details?.status)}
        </span>
      ),
      sortable: true,
      format: (row) => translateStatus(row.order_details?.status) || "N/A",
    },
    {
      name: "Actions",
      cell: (row) => (
        <div>
          {row.order_details?.status === "Pending" && (
            <Button
              color="primary"
              onClick={() => handleValidate(row.order_details)}
            >
              Valider
            </Button>
          )}
          {row.order_details?.status === "Processing" && (
            <Button
              color="success"
              onClick={() => onOpenModal(row.order_details)}
            >
              Envoyer
            </Button>
          )}
        </div>
      ),
    },
  ];

  return (
    <div>
      <Fragment>
        <DataTable
          title="Gérer les commandes"
          data={data}
          columns={columns}
          className={myClass}
          pagination={pagination}
          highlightOnHover
        />

        <Modal isOpen={open} toggle={onCloseModal}>
          <ModalHeader toggle={onCloseModal}>
            Entrer le numéro de suivi
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label htmlFor="tracking-number">Numéro de suivi :</Label>
                <Input
                  type="text"
                  id="tracking-number"
                  value={trackingNumber}
                  onChange={(e) => setTrackingNumber(e.target.value)}
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={handleShip}>
              Valider
            </Button>
            <Button color="secondary" onClick={onCloseModal}>
              Fermer
            </Button>
          </ModalFooter>
        </Modal>

        <ToastContainer />
      </Fragment>
    </div>
  );
};

export default Datatable;
