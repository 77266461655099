import React, { Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
//images import
import man from "../../../assets/images/dashboard/man.png";
import { useHistory } from "react-router-dom";

const UserMenu = () => {
  const navigate = useNavigate();

  const Logout = () => {
    localStorage.removeItem("isLogged");
    localStorage.removeItem("user");
    navigate("/auth/login");
  };
  return (
    <Fragment>
      <li className="onhover-dropdown">
        <div className="media align-items-center">
          <img
            className="align-self-center pull-right img-50 rounded-circle blur-up lazyloaded"
            src={man}
            alt="header-user"
          />
          <div className="dotted-animation">
            <span className="animate-circle"></span>
            <span className="main-circle"></span>
          </div>
        </div>
        <ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
          <li>
            <Link to={`${process.env.PUBLIC_URL}/settings/profile`}>
              <i data-feather="user"></i>Editer Profil
            </Link>
          </li>

          <li>
            <a href="#javaScript">
              <i data-feather="settings"></i>Paramètres
            </a>
          </li>
          <li onClick={Logout}>
            <i data-feather="log-out"></i>Déconnexion
          </li>
        </ul>
      </li>
    </Fragment>
  );
};

export default UserMenu;
