import React from "react";
import { Button } from "reactstrap";
import { Edit, Trash2, Search } from "react-feather";
import { useNavigate } from "react-router-dom";

const ProductListTable = ({ products, handleEditClick, handleDeleteClick }) => {
  return (
    <table className="table">
      <thead>
        <tr>
          <th>Image</th>
          <th>Title</th>
          <th>Price</th>
          <th>Discount Price</th>
          <th>Ratings</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {products.map((product, index) => (
          <tr key={index}>
            <td>
              <img
                src={product.product_images[0]?.image || "default-image-url"}
                alt=""
                style={{ width: "60px", height: "auto" }}
              />
            </td>
            <td>{product.title}</td>
            <td>{product.price}</td>
            <td>{product.discount_price}</td>
            <td>
              {[...Array(5)].map((_, i) => (
                <i
                  key={i}
                  className={`fa ${
                    i < product.average_rating ? "fa-star" : "fa-star-o"
                  }`}
                  style={{
                    color: i < product.average_rating ? "#ffc107" : "#e4e5e9",
                  }}
                ></i>
              ))}
            </td>
            <td>
              <Button
                style={{ margin: "5px" }}
                color="primary"
                type="button"
                className="mr-2"
                onClick={() => handleEditClick(product.id)}
              >
                <Edit />
              </Button>
              <Button
                style={{ margin: "5px" }}
                color="warning"
                type="button"
                className="mr-2"
                onClick={() => handleDeleteClick(product.id)}
              >
                <Trash2 />
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ProductListTable;
