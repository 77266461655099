import React, { Fragment, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Breadcrumb from "../../common/breadcrumb";
import Slider from "react-slick";
import "../../../assets/scss/slick.scss";
import "../../../assets/scss/slick-theme.scss";
import { Rating } from "react-simple-star-rating";
import {
  Button,
  Card,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
} from "reactstrap";

const ProductDetail = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [rating, setRating] = useState(1);
  const [nav, setNav] = useState({ nav1: null, nav2: null });
  const baseUrl = process.env.NEXT_PUBLIC_BASE_URL;

  useEffect(() => {
    setLoading(true);
    fetch(`${baseUrl}/product/${id}/`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erreur réseau");
        }
        return response.json();
      })
      .then((data) => {
        setProduct(data);
      })
      .catch((error) => {
        setError(error.toString());
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  useEffect(() => {
    setNav({
      nav1: Slider.slider1,
      nav2: Slider.slider2,
    });
  }, []);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  const onStarClick = (nextValue) => setRating(nextValue);
  const IncrementItem = () =>
    setQuantity(quantity < 9 ? quantity + 1 : quantity);
  const DecreaseItem = () =>
    setQuantity(quantity > 1 ? quantity - 1 : quantity);
  const handleChange = (event) => setQuantity(parseInt(event.target.value));

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;
  if (!product) return <p>No product details available</p>;

  return (
    <Fragment>
      <Breadcrumb title={product.title} parent="Physical" />
      <Container fluid={true}>
        <Card>
          <Row className="product-page-main card-body">
            <Col xl="4">
              {/* Slider for product images */}
              <Slider
                asNavFor={nav.nav2}
                ref={(slider) => (Slider.slider1 = slider)}
                className="product-slider"
              >
                {product.product_images?.map((imgObj, index) => (
                  <div key={index} className="item">
                    <img
                      className="img-fluid"
                      src={imgObj.image}
                      alt={`Product Image ${index + 1}`}
                    />
                  </div>
                ))}
              </Slider>

              <Slider
                asNavFor={nav.nav1}
                ref={(slider) => (Slider.slider2 = slider)}
                slidesToShow={4}
                swipeToSlide={true}
                focusOnSelect={true}
                className="small-slick"
              >
                {product.product_images?.map((imgObj, index) => (
                  <div key={index} className="item">
                    <img
                      className="img-fluid"
                      src={imgObj.image}
                      alt={`Thumbnail ${index + 1}`}
                    />
                  </div>
                ))}
              </Slider>
            </Col>
            <Col xl="8">
              <div className="product-page-details product-right mb-0">
                <h2>{product.title}</h2>
                <Rating
                  onClick={onStarClick}
                  ratingValue={rating}
                  size={25}
                  stars={5}
                  transition
                  name="rate1"
                />
                <hr />
                <h6 className="product-title">Product Details</h6>
                <p>{product.detail}</p>
                <div className="product-price digits mt-2">
                  <h3>${product.price.toFixed(2)}</h3>
                </div>
                <hr />
                <h6 className="product-title">Quantity</h6>
                <fieldset className="qty-box mt-2 ms-0">
                  <div className="input-group">
                    <button
                      className="btn btn-primary btn-square"
                      type="button"
                      onClick={DecreaseItem}
                    >
                      <i className="fa fa-minus"></i>
                    </button>
                    <input
                      className="form-control"
                      type="text"
                      value={quantity}
                      onChange={handleChange}
                    />
                    <button
                      className="btn btn-primary btn-square"
                      type="button"
                      onClick={IncrementItem}
                    >
                      <i className="fa fa-plus"></i>
                    </button>
                  </div>
                </fieldset>
                <div className="m-t-15">
                  <Button color="primary" className="m-r-10" type="button">
                    Add To Cart
                  </Button>
                  <Button color="secondary" type="button">
                    Buy Now
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Card>
      </Container>
    </Fragment>
  );
};

export default ProductDetail;
