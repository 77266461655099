import React, { Fragment } from 'react';
import AuthTabset from './authTabset';
import { ArrowLeft } from 'react-feather';
import Slider from 'react-slick';
import stats from '../../assets/images/dashboard/stats.png';
import '../../assets/scss/slick.scss';
import '../../assets/scss/slick-theme.scss';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';

const Auth = () => {
	var settings = {
		dots: true,
		infinite: true,
		speed: 500,
		arrows: false,
	};
	return (
		<Fragment>
			<div className='page-wrapper'>
				<div className='authentication-box'>
					<Container>
						<Row>
							<Col className='col-md-5 p-0 card-left'>
								<Card className='bg-primary'>
									<div className='svg-icon'>
										<img alt='' src={stats} className='Img-fluid' />
									</div>
									<Slider className='single-item' {...settings}>
										<div>
											<div>
												<h3>Bienvenue chez Layeli</h3>
												<p>Louez vos robes et gagnez de l'argent en les partageant avec d'autres.</p>
											</div>
										</div>
										<div>
											<div>
												<h3>Bienvenue chez Multikart</h3>
												<p>Videz votre garde robe et remplissez votre porte monnaie.</p>
											</div>
										</div>
										<div>
											<div>
												<h3>Bienvenue chez Multikart</h3>
												<p>Louer ses robes n'a jamais été aussi simple et rapide.</p>
											</div>
										</div>
									</Slider>
								</Card>
							</Col>
							<Col className='col-md-7 p-0 card-right'>
								<Card className='tab2-card'>
									<CardBody>
										<AuthTabset />
									</CardBody>
								</Card>
							</Col>
						</Row>
						<a href='' target='_blank' rel='noreferrer' className='btn btn-primary back-btn'>
							<ArrowLeft />
							Retour
						</a>
					</Container>
				</div>
			</div>
		</Fragment>
	);
};

export default Auth;
